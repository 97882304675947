import lineChartStockOptions from "components/charts/lineChart/react-lineChartStock";
import meStorage from 'components/monitoringCategories/localStorages/meStorage';
import chartUtils from 'Utilities/charts';


const LoadLineChartChartAxis = {
    id: 'meLoad-axis',
    title: {text: "Engine Load (%)"},
    min: 0,
    tickPixelInterval: 150,
    opposite: false
};
    
const LoadLineChartChartData = {
    name: '', data: [], tooltipTitle: 'ME Load', yAxis: 'meLoad-axis',
    titleSuffix: '(UTC)', yMax: 300, yMin: 0, tooltip: {valueSuffix: ' %', valueDecimals: 2}
};

const LoadLineChartPayload = {
    xAxis: "TIME",
    aggregation: "AVG",
    timeGroup: 'HOUR',
    metrics: [{
        metricCategory: "MAIN_ENGINE",
        subIds: [1],
        metricData: [{
            metricName: "load"
        }]
    }]
};

const updateLoadLineChartJson = (id, data, updateState, extraChartConfigs, widget) => {
    meStorage.setLoadLineChartData(data);
    let meLoadOverTimeJSON = Object.assign({}, lineChartStockOptions());
    meLoadOverTimeJSON.chart = {...meLoadOverTimeJSON.chart, height: 430, marginTop: 50, marginLeft: 90, marginRight: 37}
    meLoadOverTimeJSON.legend = {enabled: false};
    meLoadOverTimeJSON.xAxis.title = {...meLoadOverTimeJSON.xAxis.title, text: 'Time (UTC)'}
    meLoadOverTimeJSON.yAxis = {...LoadLineChartChartAxis};
    meLoadOverTimeJSON.series[0] = JSON.parse(JSON.stringify(LoadLineChartChartData));
    meLoadOverTimeJSON.series[0].data = data[0] ? [...data[0].values[0].value] : [];
    meLoadOverTimeJSON.tooltip.borderColor = chartUtils.tootlipBorderColor.premiumLight();

    //if detailed data exists with map bullet clicked or alert clicked, add the plotLines and plotBands (guides) to line charts
    if(extraChartConfigs && extraChartConfigs.plotLines) {
      let plotGuides = extraChartConfigs.plotLines.setReportsPlotLineGuides(data);
      if (plotGuides?.xAxis) {
        meLoadOverTimeJSON.xAxis = Object.assign({}, meLoadOverTimeJSON.xAxis, plotGuides.xAxis);
      }
    }

    const chartHasData = meLoadOverTimeJSON.series.find(serie => serie?.data?.length > 0);
    if(!chartHasData) meLoadOverTimeJSON = {};
    
    updateState(id, meLoadOverTimeJSON, widget?.id);
};


export { LoadLineChartPayload, updateLoadLineChartJson };
