import meStorage from "../../components/monitoringCategories/localStorages/meStorage";
import _ from "lodash";

const sortObj = (obj) => {
    const ordered = {};
    _.keys(mainEngineHardCodedKeys.general).forEach(key => {
        ordered[key] = obj[key] ? obj[key] : null;
    })
    return ordered;
}

export const mainEngineHardCodedKeys = {
    names: [
        {title: 'SHAFT', name: 'shaft'},
        {title: 'FUEL OIL', name: 'fOil'},
        {title: 'LUBE OIL', name: 'lOil'},
        {title: 'WATER', name: 'water'},
        {title: 'AIR', name: 'air'}
    ],
    general: {
        'hpsBearingTemp': {'name': 'Intermediate Shaft Bearing Temperature (' + String.fromCharCode(176) + 'C)'},
        'rpm': {'name': 'RPM'},
        'thrustPadTemp': {'name': 'Thrust Pad Temperature (' + String.fromCharCode(176) + 'C)'},
        'power': {'name': 'Power (kW)'},
        'torque': {'name': 'Torque (N/m)'},
        'airCoolerCWInLETPress': {'name': 'Air Cooler Cooling Water Inlet Pressure (bar)'},
        'coolerCWinTemp': {'name': 'Air Cooler Cooling Water Inlet Temperature (' + String.fromCharCode(176) + 'C)'},
        'jcfWInTempLow': {'name': 'Jacket Cooling Fresh Water Inlet Temperature Low (' + String.fromCharCode(176) + 'C)'},
        'jcfwInLETPress': {'name': 'Jacket Cooling Fresh Water Inlet Pressure (bar)'},
        'controlAirPress': {'name': 'Control Air Pressure (bar)'},
        'cylLoTemp': {'name': 'Cylinder Lube Oil Temperature (' + String.fromCharCode(176) + 'C)'},
        'exhVVSpringAirInPress': {'name': 'Exhaust Valve Spring Air Inlet Pressure (bar)'},
        'foFlow': {'name': 'Fuel Oil Flowrate (lt)'},
        'mainLOInLetPress': {'name': 'Main Lube Oil Inlet Pressure (bar)'},
        'mainLOInTemp': {'name': 'Main Lube Oil Inlet Temperature (' + String.fromCharCode(176) + 'C)'},
        'scavAirReceiverTemp': {'name': 'Scavenge Air Receiver Temperature (' + String.fromCharCode(176) + 'C)'},
        'startAirPress': {'name': 'Starting Air Pressure (bar)'},
        'scavengeAirPressure': {'name': 'Scavenge Air Pressure (bar)'},
        'foInPress': {'name': 'Fuel Oil Inlet Pressure (bar)'},
        'foInTemp': {'name': 'Fuel Oil Inlet Temperature (' + String.fromCharCode(176) + 'C)'},
        'foTemperature': {'name': 'Fuel Oil Temperature (' + String.fromCharCode(176) + 'C)'},
        'foVolConsumption': {'name': 'Fuel Oil Consumption (kg/min)'}
    },
    mainEngineMaxValuesForBarsTable: {
        'airCoolerCWInLETPress': 6,
        'controlAirPress': 10,
        'coolerCWinTemp': 800,
        'cylLoTemp': 200,
        'exhVVSpringAirInPress': 10,
        'foVolConsumption': 300,
        'foFlow': 150,
        'foInPress': 16,
        'foInTemp': 200,
        'foTemperature': 200,
        'jcfwInLETPress': 10,
        'hpsBearingTemp': 100,
        'jcfWInTempLow': 200,
        'mainLOInLetPress': 6,
        'mainLOInTemp': 200,
        'power': 40000,
        'rpm': 120,
        'scavAirReceiverTemp': 200,
        'scavengeAirPressure': 6,
        'startAirPress': 40,
        'thrustPadTemp': 200,
        'torque': 5000
    },
    navigationMapper: {
        shaft: ['rpm', 'power', 'torque', 'thrustPadTemp', 'hpsBearingTemp'],
        fOil: ['foInPress', 'foInTemp', 'foTemperature'],
        lOil: ['cylLoTemp', 'mainLOInLetPress', 'mainLOInTemp'],
        water: ['jcfWInTempLow', 'jcfwInLETPress', 'airCoolerCWInLETPress', 'coolerCWinTemp'],
        air: ['startAirPress', 'controlAirPress', 'scavengeAirPressure', 'scavAirReceiverTemp', 'exhVVSpringAirInPress']
    }
}

export const mainEngineSyncedChartsConfig = {
    chartAxes: {
        shaft: [
            { id: 'rpm-axis', title: { text: 'RPM'}, height: '80px', top: '50px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
            { id: 'power-axis', title: { text: 'Power (kW)'}, height: '80px', top: '170px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
            { id: 'torque-axis', title: { text: 'Torque (kNm)'}, height: '80px', top: '290px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
            { id: 'thrustPadTemp-axis', title: { text: 'Thrust Pad Temp (°C)'}, height: '80px', top: '410px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
            { id: 'hpsBearingTemp-axis', title: { text: 'Intermediate Shaft Bearing Temp (°C)'}, height: '80px', top: '530px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
        ],
        fOil: [
            { id: 'foInPress-axis', title: { text: 'Fuel Oil Inlet Press (bar)'}, height: '130px', top: '50px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
            { id: 'foInTemp-axis', title: { text: 'Fuel Oil Inlet Temp (°C)'}, height: '130px', top: '250px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
            { id: 'foTemperature-axis', title: { text: 'Fuel Oil Temp (°C)'}, height: '130px', top: '450px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
        ],
        lOil: [
            { id: 'cylLoTemp-axis', title: { text: 'Cylinder Lube Oil Temp (°C)'}, height: '130px', top: '50px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
            { id: 'mainLOInLetPress-axis', title: { text: 'Main Lube Oil Inlet Press (bar)'}, height: '130px', top: '250px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
            { id: 'mainLOInTemp-axis', title: { text: 'Main Lube Oil Inlet Temp (°C)'}, height: '130px', top: '450px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
        ],
        water: [
            { id: 'jcfWInTempLow-axis', title: { text: 'Jacket Cooling Fresh Water Inlet Temp Low (°C)'}, height: '100px', top: '50px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
            { id: 'jcfwInLETPress-axis', title: { text: 'Jacket Cooling Fresh Water Inlet Press (bar)'}, height: '100px', top: '200px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
            { id: 'airCoolerCWInLETPress-axis', title: { text: 'Air Cooler Cooling Water Inlet Press (bar)'}, height: '100px', top: '350px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
            { id: 'coolerCWinTemp-axis', title: { text: 'Air Cooler Cooling Water Inlet Temp (°C)'}, height: '100px', top: '500px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
        ],
        air: [
            { id: 'startAirPress-axis', title: { text: 'Starting Air Press (bar)'}, height: '80px', top: '50px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
            { id: 'controlAirPress-axis', title: { text: 'Control Air Press (bar)'}, height: '80px', top: '170px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
            { id: 'scavengeAirPressure-axis', title: { text: 'Scavenge Air Press (bar)'}, height: '80px', top: '290px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
            { id: 'scavAirReceiverTemp-axis', title: { text: 'Scavenge Air Receiver Temp (°C)'}, height: '80px', top: '410px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
            { id: 'exhVVSpringAirInPress-axis', title: { text: 'Exhaust Valve Spring Air Inlet Press (bar)'}, height: '80px', top: '530px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
        ]
    },
    chartSeries: {
        shaft: [
            { id: 'rpm', name: 'RPM', data: [], tooltipTitle: 'RPM', type: 'line', yAxis: 'rpm-axis',
                titleSuffix: '(UTC)', tooltip: { valueSuffix: '', valueDecimals: 2 } },
            { id: 'power', name: 'Power', data: [], tooltipTitle: 'Power', type: 'line', yAxis: 'power-axis',
                titleSuffix: '(UTC)', tooltip: { valueSuffix: ' kW', valueDecimals: 2 } },
            { id: 'torque', name: 'Torque', data: [], tooltipTitle: 'Torque', type: 'line', yAxis: 'torque-axis',
                titleSuffix: '(UTC)', tooltip: { valueSuffix: ' kNm', valueDecimals: 2 } },
            { id: 'thrustPadTemp', name: 'Thrust Pad Temp', data: [], tooltipTitle: 'Thrust Pad Temp', type: 'line', yAxis: 'thrustPadTemp-axis',
                titleSuffix: '(UTC)', tooltip: { valueSuffix: ' °C', valueDecimals: 2 } },
            { id: 'hpsBearingTemp', name: 'Intermediate Shaft Bearing Temp', data: [], tooltipTitle: 'Intermediate Shaft Bearing Temp', type: 'line', yAxis: 'hpsBearingTemp-axis',
                titleSuffix: '(UTC)', tooltip: { valueSuffix: ' °C', valueDecimals: 2 } }
        ],
        fOil: [
            { id: 'foInPress', name: 'Fuel Oil Inlet Press', data: [], tooltipTitle: 'Fuel Oil Inlet Press', type: 'line', yAxis: 'foInPress-axis',
                titleSuffix: '(UTC)', tooltip: { valueSuffix: ' bar', valueDecimals: 2 } },
            { id: 'foInTemp', name: 'Fuel Oil Inlet Temp', data: [], tooltipTitle: 'Fuel Oil Inlet Temp', type: 'line', yAxis: 'foInTemp-axis',
                titleSuffix: '(UTC)', tooltip: { valueSuffix: ' °C', valueDecimals: 2 } },
            { id: 'foTemperature', name: 'Fuel Oil Temp', data: [], tooltipTitle: 'Fuel Oil Temp', type: 'line', yAxis: 'foTemperature-axis',
                titleSuffix: '(UTC)', tooltip: { valueSuffix: ' °C', valueDecimals: 2 } }
        ],
        lOil: [
            { id: 'cylLoTemp', name: 'Cylinder Lube Oil Temp', data: [], tooltipTitle: 'Cylinder Lube Oil Temp', type: 'line', yAxis: 'cylLoTemp-axis',
                titleSuffix: '(UTC)', tooltip: { valueSuffix: ' °C', valueDecimals: 2 } },
            { id: 'mainLOInLetPress', name: 'Main Lube Oil Inlet Press', data: [], tooltipTitle: 'Main Lube Oil Inlet Press', type: 'line', yAxis: 'mainLOInLetPress-axis',
                titleSuffix: '(UTC)', tooltip: { valueSuffix: ' bar', valueDecimals: 2 } },
            { id: 'mainLOInTemp', name: 'Main Lube Oil Inlet Temp', data: [], tooltipTitle: 'Main Lube Oil Inlet Temp', type: 'line', yAxis: 'mainLOInTemp-axis',
                titleSuffix: '(UTC)', tooltip: { valueSuffix: ' °C', valueDecimals: 2 } }
        ],
        water: [
            { id: 'jcfWInTempLow', name: 'Jacket Cooling Fresh Water Inlet Temp Low', data: [], tooltipTitle: 'Jacket Cooling Fresh Water Inlet Temp Low', type: 'line', yAxis: 'jcfWInTempLow-axis',
                titleSuffix: '(UTC)', tooltip: { valueSuffix: ' °C', valueDecimals: 2 } },
            { id: 'jcfwInLETPress', name: 'Jacket Cooling Fresh Water Inlet Press', data: [], tooltipTitle: 'Jacket Cooling Fresh Water Inlet Press', type: 'line', yAxis: 'jcfwInLETPress-axis',
                titleSuffix: '(UTC)', tooltip: { valueSuffix: ' bar', valueDecimals: 2 } },
            { id: 'airCoolerCWInLETPress', name: 'Air Cooler Cooling Water Inlet Press', data: [], tooltipTitle: 'Air Cooler Cooling Water Inlet Press', type: 'line', yAxis: 'airCoolerCWInLETPress-axis',
                titleSuffix: '(UTC)', tooltip: { valueSuffix: ' bar', valueDecimals: 2 } },
            { id: 'coolerCWinTemp', name: 'Air Cooler Cooling Water Inlet Temp', data: [], tooltipTitle: 'Air Cooler Cooling Water Inlet Temp', type: 'line', yAxis: 'coolerCWinTemp-axis',
                titleSuffix: '(UTC)', tooltip: { valueSuffix: ' °C', valueDecimals: 2 } }
        ],
        air: [
            { id: 'startAirPress', name: 'Starting Air Press ', data: [], tooltipTitle: 'Starting Air Press', type: 'line', yAxis: 'startAirPress-axis',
                titleSuffix: '(UTC)', tooltip: { valueSuffix: ' bar', valueDecimals: 2 } },
            { id: 'controlAirPress', name: 'Control Air Press', data: [], tooltipTitle: 'Control Air Press', type: 'line', yAxis: 'controlAirPress-axis',
                titleSuffix: '(UTC)', tooltip: { valueSuffix: ' bar', valueDecimals: 2 } },
            { id: 'scavengeAirPressure', name: 'Scavenge Air Press ', data: [], tooltipTitle: 'Scavenge Air Press', type: 'line', yAxis: 'scavengeAirPressure-axis',
                titleSuffix: '(UTC)', tooltip: { valueSuffix: ' bar', valueDecimals: 2 } },
            { id: 'scavAirReceiverTemp', name: 'Scavenge Air Receiver Temp ', data: [], tooltipTitle: 'Scavenge Air Receiver Temp', type: 'line', yAxis: 'scavAirReceiverTemp-axis',
                titleSuffix: '(UTC)', tooltip: { valueSuffix: ' °C', valueDecimals: 2 } },
            { id: 'exhVVSpringAirInPress', name: 'Exhaust Valve Spring Air Inlet Press ', data: [], tooltipTitle: 'Exhaust Valve Spring Air Inlet Press', type: 'line', yAxis: 'exhVVSpringAirInPress-axis',
                titleSuffix: '(UTC)', tooltip: { valueSuffix: ' bar', valueDecimals: 2 } }
        ]
    },
}

const setBarTablesDataFormat = (barsTableData) => {
    let tmpObj = {};
    barsTableData.forEach(obj => tmpObj[obj.metricName] = obj.value);
    return tmpObj;
};

export const generalBarsTablePayload = {
    xAxis: "TIME",
    aggregation: "AVG",
    timeGroup: 'HOUR',
    metrics: [
        {
            metricCategory: "MAIN_ENGINE",
            subIds: [1],
            metricData: [
                {metricName: "hpsBearingTemp", aggregation: 'AVG'},
                {metricName: "rpm", aggregation: 'AVG'},
                {metricName: "thrustPadTemp", aggregation: 'AVG'},
                {metricName: "power", aggregation: 'AVG'},
                {metricName: "torque", aggregation: 'AVG'},
                {metricName: "airCoolerCWInLETPress", aggregation: 'AVG'},
                {metricName: "coolerCWinTemp", aggregation: 'AVG'},
                {metricName: "jcfWInTempLow", aggregation: 'AVG'},
                {metricName: "jcfwInLETPress", aggregation: "AVG"},
                {metricName: "controlAirPress", aggregation: 'AVG'},
                {metricName: "cylLoTemp", aggregation: 'AVG'},
                {metricName: "exhVVSpringAirInPress", aggregation: 'AVG'},
                {metricName: "foFlow", aggregation: 'AVG'},
                {metricName: "mainLOInLetPress", aggregation: 'AVG'},
                {metricName: "mainLOInTemp", aggregation: 'AVG'},
                {metricName: "scavAirReceiverTemp", aggregation: 'AVG'},
                {metricName: "startAirPress", aggregation: 'AVG'},
                {metricName: "scavengeAirPressure", aggregation: 'AVG'},
                {metricName: "foInPress", aggregation: 'AVG'},
                {metricName: "foInTemp", aggregation: 'AVG'},
                {metricName: "foTemperature", aggregation: 'AVG'},
                {metricName: "foVolConsumption", aggregation: 'AVG'}
            ]
        }
    ]
}

export const updateGeneralBarsTable = (id, data, updateState, extraChartConfigs, widget) => {
    meStorage.setGeneralBarsTableData(data);
    const barsTableData = (data[0]?.values) ? setBarTablesDataFormat(data[0]?.values) : [];

    let sortedData = sortObj(barsTableData);
    const sortedDataNotEmpty = Object.values(sortedData).find(value => value !== null);
    if(!sortedDataNotEmpty) sortedData = {};

    updateState(id, sortedData, widget?.id);
}
