import lineChartStockOptions from "components/charts/lineChart/react-lineChartStock";
import meStorage from 'components/monitoringCategories/localStorages/meStorage';
import {licensing} from "../../common/store/licensing";
import chartUtils from 'Utilities/charts';
import { ThemeColorsForCharts } from 'Utilities/themeColorsForCharts';
import { vesselUtils } from 'common/store/storeUtils';

let pData = [];

const fuelConsumptionChartData = [ 
    {name: 'Sensor FC', data: [], tooltipTitle: 'Sensor FC', yAxis: 'fuel-axis',
        titleSuffix:'(UTC)', yMax: 300, yMin: 0, tooltip: {valueSuffix: ' kg/min', valueDecimals: 2}}, 
    {name: 'Expected FC', tooltipTitle: 'Expected FC', yAxis: 'fuel-axis',
        titleSuffix:'(UTC)', yMax: 300, yMin: 0, data: [], tooltip: {valueSuffix: ' kg/min', valueDecimals: 2}}
];

const fuelConsumptionChartData_light = [{
    name: 'Report FC', data: [], tooltipTitle: 'Report FC', yAxis: 'fuel-axis', color: ThemeColorsForCharts()['reports_color'],
    titleSuffix: '(UTC)', yMax: 300, yMin: 0, tooltip: {valueSuffix: ' mt', valueDecimals: 2}
}];

const fuelConsumptionChartAxis = {id: 'fuel-axis', title: {text: "Fuel Consumption (kg/min)"}, min: 0, tickPixelInterval: 150, opposite: false};
const fuelConsumptionChartAxis_light = {id: 'fuel-axis', title: {text: "Fuel Consumption (mt)"}, min: 0, tickPixelInterval: 150, opposite: false};

const FuelLineChartPayload = {
    xAxis: "TIME",
    aggregation: "AVG",
    timeGroup: 'HOUR',
    metrics:[
        {
            metricCategory: "MAIN_ENGINE",
            subIds: [1],
            metricData: [
                {
                    metricName: "foVolConsumption"
                }
            ]
        },
    ]
};

const FuelLineChartPayload_light = {
    xAxis: "TIME",
    aggregation: "AVG",
    timeGroup: 'HOUR',
    metrics:[
        {
            metricCategory: "TELEGRAM",
            subIds: [1],
            metricData: [
                {
                    metricName: "me_1_foConsumption_calc"
                }
            ]
        },
    ]
};

const FuelLineChartPredictedPayload = {
    predictionCategory: "ENGINE_CONSUMPTION",
    timeGroup: "HOUR",
    aggregation: "AVG",
    metrics: ["mean_prediction"]
}

const setPData = data => {
    if(!data?.length) return pData = [];
    pData = JSON.parse(JSON.stringify(data[0].values || []));
}

const updateFuelLineChartJson = (id, data, updateState, extraChartConfigs, widget) => {
    meStorage.setFuelLineChartData(data);
    const isLight = licensing.lightCondition(null, vesselUtils.getObjOfAVessel(widget?.vesselIds[0]));

    //set main engine fuel consumption json with data
    let lineChartFoConsumptionJSON = Object.assign({}, lineChartStockOptions());
    lineChartFoConsumptionJSON.chart = {...lineChartFoConsumptionJSON.chart, height: 469, marginTop: 85, marginLeft: 91, marginRight: 38};
    lineChartFoConsumptionJSON.series = [];
    lineChartFoConsumptionJSON.yAxis = isLight ? {...fuelConsumptionChartAxis_light} : {...fuelConsumptionChartAxis};
    lineChartFoConsumptionJSON.xAxis.title = {...lineChartFoConsumptionJSON.xAxis.title, text: 'Time (UTC)'}
    lineChartFoConsumptionJSON.series = isLight ? JSON.parse(JSON.stringify(fuelConsumptionChartData_light)) : JSON.parse(JSON.stringify(fuelConsumptionChartData));
    lineChartFoConsumptionJSON.tooltip.borderColor = chartUtils.tootlipBorderColor.mixedDataWithReports();

    data && data[0] && data[0].values[0].value.forEach(val => {val.y = parseFloat(val.y)});
    
    if (isLight) {
        lineChartFoConsumptionJSON.legend.enabled = false;
    }

    lineChartFoConsumptionJSON.series[0].data = data[0] ? [...data[0].values[0].value] : [];

    if (!isLight) lineChartFoConsumptionJSON.series[1].data = JSON.parse(JSON.stringify(pData));

    //if detailed data exists with map bullet clicked or alert clicked, add the plotLines and plotBands (guides) to line charts
    if (extraChartConfigs && extraChartConfigs.plotLines) {
        let plotGuides = extraChartConfigs.plotLines.setReportsPlotLineGuides(data);
        if (plotGuides?.xAxis) {
            lineChartFoConsumptionJSON.xAxis = Object.assign({}, lineChartFoConsumptionJSON.xAxis, plotGuides.xAxis);
        }
    }

    const chartHasData = lineChartFoConsumptionJSON.series.find(serie => serie?.data?.length > 0);
    if(!chartHasData) lineChartFoConsumptionJSON = {};
    
    updateState(id, lineChartFoConsumptionJSON, widget?.id);
}

export {
    FuelLineChartPayload,
    FuelLineChartPredictedPayload,
    FuelLineChartPayload_light,
    updateFuelLineChartJson,
    setPData
};
