import React from 'react';

const PowerChartFooter = () => {
    return(
        <div className="full-width widget-footer-legend powerChartFooter small-label flex-centered-start marg-l-15">
            Fuel calorific value for Shop Test - ISO: 10200 kcal/kg, HFO: 9600 kcal/kg and LFO: 9800 kcal/kg
        </div>
    )
}

export default PowerChartFooter;