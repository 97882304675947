import meStorage from "../../components/monitoringCategories/localStorages/meStorage";
import highstockBarChartOptions from 'components/charts/barChart/highstock-barChart';
import chartUtils from 'Utilities/charts';


const exhGasAxis = [{id: 'exhGas-axis', title: {text: ""}, opposite: false, crosshair: false}];
const exhGasData = [
  {name:"P1", cylinder: '1', type:"column", yAxis:"exhGas-axis", titleSuffix:'(UTC)', data:[], tooltip: {}}, {name:"P1 min-max", cylinder: '1', type:"errorbar", yAxis:"exhGas-axis", data:[], tooltip: {}},
  {name:"P2", cylinder: '2', type:"column", yAxis:"exhGas-axis", titleSuffix:'(UTC)', data:[], tooltip: {}}, {name:"P2 min-max", cylinder: '2', type:"errorbar", yAxis:"exhGas-axis", data:[], tooltip: {}},
  {name:"P3", cylinder: '3', type:"column", yAxis:"exhGas-axis", titleSuffix:'(UTC)', data:[], tooltip: {}}, {name:"P3 min-max", cylinder: '3', type:"errorbar", yAxis:"exhGas-axis", data:[], tooltip: {}},
  {name:"P4", cylinder: '4', type:"column", yAxis:"exhGas-axis", titleSuffix:'(UTC)', data:[], tooltip: {}}, {name:"P4 min-max", cylinder: '4', type:"errorbar", yAxis:"exhGas-axis", data:[], tooltip: {}},
  {name:"P5", cylinder: '5', type:"column", yAxis:"exhGas-axis", titleSuffix:'(UTC)', data:[], tooltip: {}}, {name:"P5 min-max", cylinder: '5', type:"errorbar", yAxis:"exhGas-axis", data:[], tooltip: {}},
  {name:"P6", cylinder: '6', type:"column", yAxis:"exhGas-axis", titleSuffix:'(UTC)', data:[], tooltip: {}}, {name:"P6 min-max", cylinder: '6', type:"errorbar", yAxis:"exhGas-axis", data:[], tooltip: {}},
  {name:"P7", cylinder: '7', type:"column", yAxis:"exhGas-axis", titleSuffix:'(UTC)', data:[], tooltip: {}}, {name:"P7 min-max", cylinder: '7', type:"errorbar", yAxis:"exhGas-axis", data:[], tooltip: {}},
  {name:"P8", cylinder: '8', type:"column", yAxis:"exhGas-axis", titleSuffix:'(UTC)', data:[], tooltip: {}}, {name:"P8 min-max", cylinder: '8', type:"errorbar", yAxis:"exhGas-axis", data:[], tooltip: {}},
  {name:"P9", cylinder: '9', type:"column", yAxis:"exhGas-axis", titleSuffix:'(UTC)', data:[], tooltip: {}}, {name:"P9 min-max", cylinder: '9', type:"errorbar", yAxis:"exhGas-axis", data:[], tooltip: {}},
  {name:"P10", cylinder: '10', type:"column", yAxis:"exhGas-axis", titleSuffix:'(UTC)', data:[], tooltip: {}}, {name:"P10 min-max", cylinder: '10',type:"errorbar", yAxis:"exhGas-axis", data:[], tooltip: {}},
  {name:"P11", cylinder: '11', type:"column", yAxis:"exhGas-axis", titleSuffix:'(UTC)', data:[], tooltip: {}}, {name:"P11 min-max", cylinder: '11', type:"errorbar", yAxis:"exhGas-axis", data:[], tooltip: {}},
  {name:"P12", cylinder: '12', type:"column", yAxis:"exhGas-axis", titleSuffix:'(UTC)', data:[], tooltip: {pointFormat: ''}}, {name:"P12 min-max", cylinder: '12', type:"errorbar", yAxis:"exhGas-axis", data:[], tooltip: {}},
];

const buildExhGasData = (data) => {
    data.forEach(element => {
        if (element.type !== 'errorbar') {
            element.tooltip = {
                pointFormat: `<div class="flex-space-between highcharts-fonts" style="width: 100%;">
                                <div class="highcharts-fonts" style="display: inline-flex;">
                                    <span class="tooltip-circle" style="background-color:{series.color}; margin-top: 4px;"></span>
                                    <span class="highcharts-fonts" style="margin: 0 24px 0px 2px;">{series.name}: &nbsp;</span>
                                    <span class="per-value highcharts-fonts"><b>{point.y:.2f} %</b></span>
                                </div>`
            }
        } 
        else {
            element.tooltip = {
                valueDecimals: 2,
                pointFormat: `<div style="display: inline-flex; align-items: center;">
                                <span style="margin-left: 5px" class="per-min-value highcharts-fonts">
                                    {&#32;}(min: <b>{point.tempMin:.2f} ${String.fromCharCode(176)} C </b>
                                </span>
                                <span class="per-max-value highcharts-fonts">
                                    &nbsp; / max: <b>{point.tempMax:.2f} ${String.fromCharCode(176)} C</b>)
                                </span>
                            </div></div>`
            }
        }
    })
    
    return data;
}


// Functionality to pass custom component for legend under a query selected by classname div
/*export const highchartsCallback = chart => {
    if (!chart) return;
    let chartProps = chart.series.filter(serie => serie.type !== 'errorbar').filter(serie => serie.type !== 'areaspline');

    ReactDOM.render(
        <CustomLegend chartProps={chartProps} title={"PISTONS"} legendTextType={"index"} legendIconType={"circle"} />,
        document.querySelector('.legendArea')
    )

    // HTML below should be added under the chart
    // <div className='legendArea' />

    // This function should be added as callback in HighchartsReact component
    // <HighchartsReact options={props.data} constructorType={"stockChart"} highcharts={Highstock} callback={props.highchartsCallback} />
}*/

export const updateDeviationChartJson = (id, data, updateState, extraChartConfigs, widget) => {
    meStorage.setDeviationData(data);
    let tmpArr = [];

    let exhGasBarChartJSON = JSON.parse(JSON.stringify(highstockBarChartOptions()));
    exhGasBarChartJSON.yAxis = exhGasAxis;

    exhGasBarChartJSON.tooltip = {
        headerFormat: 
            `<div class="flex-centered-col highcharts-fonts">
                <div class="highcharts-fonts tooltip-title"><b>
                    {series.userOptions.tootltipXTitle}{point.key}&nbsp;{series.userOptions.titleSuffix}</b>
                </div>
                <div class="tooltip-values" style="width: 100%;">`,
        footerFormat: `</div></div>`,
        shared: true,
        useHTML: true,
        split: false,
        followPointer: false,
        xDateFormat: '%d/%m %H:%M',
    }

    exhGasBarChartJSON.chart.height = 810;
    exhGasBarChartJSON.chart.events = {
        load: function () {
            const chart = this,
                series = this.series[0],
                xAxis = chart.xAxis[0],
                newStart = series?.xData[series.xData.length - 1],
                newEnd = series?.xData[series.xData.length - 8];

            xAxis.setExtremes(newStart, newEnd);
        }
    }
    exhGasBarChartJSON.series = buildExhGasData(JSON.parse(JSON.stringify(exhGasData)));
    exhGasBarChartJSON.plotOptions.column.pointWidth = 6;
    exhGasBarChartJSON.plotOptions.series.groupPadding = 0.1;
    exhGasBarChartJSON.chart.marginTop = 127;
    exhGasBarChartJSON.legend.enabled = true;
    exhGasBarChartJSON.legend.symbolPadding = 0;
    exhGasBarChartJSON.legend.y = 57;
    exhGasBarChartJSON.exporting.buttons.contextButton.x = 5;
    exhGasBarChartJSON.exporting.buttons.contextButton.y = 30;
    exhGasBarChartJSON.exporting.csv.dateFormat = '%Y-%m-%d %H:%M:%S';
    exhGasBarChartJSON.tooltip.borderColor = chartUtils.tootlipBorderColor.premiumLight();

    exhGasBarChartJSON.series.forEach((serie, index) => {
        serie.data = [];
        if (!data.length) return serie.data = [];

        if (!data[0].deviationStatsPerPiston[serie.cylinder]) {
            exhGasBarChartJSON.series.splice(index, 1);
            return;
        }

        tmpArr = data[0].deviationStatsPerPiston[serie.cylinder];

        if (serie.type === "errorbar") {
            tmpArr.forEach(element => {
                serie.color = "#8896a3";
                serie.data.push({
                    low: element?.deviationMinMax?.min || null,
                    high: element?.deviationMinMax?.max || null,
                    tempMin: element?.temperatureMinMax?.min || null,
                    tempMax: element?.temperatureMinMax?.max || null,
                    x: element?.datetime || null
                });
            });
        } else {
            tmpArr.forEach(element => {
                serie.data.push({
                    x: element?.datetime || null,
                    y: element?.deviation || null
                });
            });
        }
    });

    const chartHasData = exhGasBarChartJSON.series.find(serie => serie?.data?.length > 0);
    if(!chartHasData) exhGasBarChartJSON = {};

    setTimeout(() => updateState(id, exhGasBarChartJSON, widget?.id));
}
