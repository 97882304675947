import meStorage from 'components/monitoringCategories/localStorages/meStorage';
import ThemeColors from "sass/_themeColorsForJs.scss";
import chartOptions from "components/charts/lineChart/react-lineChart";
import chartUtils from 'Utilities/charts';
import { vesselUtils } from 'common/store/storeUtils';

let TCRpmPowerCurvesData = {}, 
    TCRpmPowerCurvesDataObj = {}, 
    scatterData = {},
    chartSeries = [],
    columnHeaderMapper = {};

const yAxisData = [{id: 'rpm-axis', title: {text: "T/C RPM"}, opposite: false}];

const buildChartSeriesAndFillWithData = (curvesData, scatterData) => {
    chartSeries = [];
    const curvesDataToFill = curvesData?.SHOP_TEST || [];
    const scatterKeys = Object?.keys(scatterData?.DEFAULT);

    // build the scatter series depending on how many turbo chargers we receive
    scatterKeys.forEach((key, index) => {
        const scatterDataToFill = scatterData?.DEFAULT[key] || [];

        chartSeries[index] = {
            name: key, 
            data: scatterDataToFill, 
            tooltipTitle: `${key} RPM`, 
            yAxis: 'rpm-axis', 
            type: 'scatter', 
            marker: {enabled: true, symbol: 'circle'},
            tooltip: {valueDecimals: 0},
            showInLegend: true, // the TC series should always appear in legend, even if they do not have data (empty array)
            visible: scatterDataToFill?.length > 0, // in case the series does not have data, then it should be deactivated in the legend
            customTooltipBorderColor: 'defaultSeriesColor' // the tooltip border color for the scatter series should be the same as the series default color
        };
        columnHeaderMapper[key] = `${key} (rpm)`;
    });

    // last, put the shop-test series along with it's data
    chartSeries[scatterKeys?.length] = {
        name: 'Shop Test', data: curvesDataToFill, tooltipTitle: 'Shop Test T/C RPM', yAxis: 'rpm-axis', color: ThemeColors['first_reference_line'],
        titleSuffix:'Kn', tootltipXTitle: 'Power: ', tooltip: {valueDecimals: 0}, showInLegend: curvesDataToFill?.length
    }
    columnHeaderMapper['Shop Test'] = 'Shop Test (rpm)';
}

// the data that we set here are for the rpm power curves (called in the prerequisite)
const setTCRpmPowerCurvesData = (data, widget) => {    
    if (widget?.inDashboard) TCRpmPowerCurvesDataObj[widget.id] = data?.meCurves?.DEFAULT?.curves ? data.meCurves.DEFAULT.curves : {};
    else TCRpmPowerCurvesData = data?.meCurves?.DEFAULT?.curves ? data.meCurves.DEFAULT.curves : {};
}

// the data that we receive here are for the scatter plots
const updateTCRpmPowerChart = (id, data, updateState, extraChartConfigs, widget) => {

    if(meStorage.TCRpmPowerScatterPlotsData) scatterData = meStorage.TCRpmPowerScatterPlotsData;
    else scatterData = data;

    if(meStorage.TCRpmPowerData) TCRpmPowerCurvesData = meStorage.TCRpmPowerData;

    if (widget?.inDashboard) {
        TCRpmPowerCurvesData = TCRpmPowerCurvesDataObj[widget?.id];
        scatterData = data;
    }

    // handle empty data case, return empty object so that the noData component appears (show the NoData component when we do not have any scatter data)
    if(!scatterData) return updateState(id, {}, widget?.id);

    const chartData = Object.assign({}, chartOptions(':.2f', '%'));

    // set the series data of the chart
    chartData.yAxis = yAxisData;
    buildChartSeriesAndFillWithData(TCRpmPowerCurvesData, scatterData);
    chartData.series = [...chartSeries];

    // set some chart options that are common in curves-scatter charts
    chartUtils.curvesAndScatterDataChart.setCommonChartOptions(chartData);

    // set extra options that are specific for this chart
    chartData.chart.height = 360;
    chartData.chart.marginTop = 95;
    chartData.legend.y = 15;
    chartData.xAxis.title = { text: "Power (kW)", y: 10 };
    chartData.exporting.filename = `${vesselUtils.getVesselName(widget.vesselIds[0])}-TURBOCHARGER RPM VS POWER`;
    chartData.exporting.csv.columnHeaderFormatter = (item) => chartUtils.curvesAndScatterDataChart.setExportingColumns(item, columnHeaderMapper, 'Power (kW)');

    // a common formatter for creating the tooltip's UI for both scatter data and curves data
    const tooltipExtraOptions = {
        titleX: 'Power: ',
        valueXSuffix: 'kW',
        width: '120px'
    };
    chartData.tooltip.formatter = function() {return chartUtils.curvesAndScatterDataChart.tooltipFormatter.call(this, tooltipExtraOptions)};

    if (!widget?.inDashboard) {
        meStorage.setTCRpmPowerData(TCRpmPowerCurvesData);
        meStorage.setTCRpmPowerScatterPlotsData(scatterData);
    }

    TCRpmPowerCurvesData = {};
    scatterData = {};

    return updateState(id, chartData, widget?.id);
}

export { updateTCRpmPowerChart, setTCRpmPowerCurvesData };