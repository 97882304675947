import React, {useEffect, useState} from 'react';
import SyncCharts from "../charts/genericSyncCharts/syncCharts";
import syncChartsOptions from "../charts/genericSyncCharts/syncChartsOptions";
import NavigationTabs from "../navigationTabs/navigationTabs";
import {monitoringStore} from 'common/store/storeUtils';
import moment from 'moment';

const SyncedChartWithTabNavigation = props => {
    const [syncCharts, setSyncCharts] = useState(syncChartsOptions());
    const [currentTab, setCurrentTab] = useState(props.tabs[0]?.name);
    const updateCurrentTab = tab => {
        setCurrentTab(tab);
    }

    useEffect(() => {
        if (!props.data) return;
        let options = syncChartsOptions();

        options["series"] = props.chartSeries[currentTab];
        options["yAxis"] = props.chartAxes[currentTab];
        options.chart.height = props.chartHeight;
        options.title = props?.title || '';
        options.elementId = props.elementId || `${moment().valueOf()}`;
        if(props.downloadIconY) options.exporting.buttons.contextButton.y = props.downloadIconY;
        if(options?.exporting?.chartOptions) {
            options.exporting.chartOptions.series = props.chartSeries[currentTab];
            options.exporting.chartOptions.yAxis = props.chartAxes[currentTab];
        }
        options.exporting.filename = props?.exportingChartTitle;
        options.exporting.chartOptions.title.text = `${props?.tabs?.find((tab) => tab.name === currentTab)?.title} details`;
        options.xAxis.plotLines =  props.plotLines;
        if(props.multiplePlotLines) options.xAxis.plotLines =  props.multiplePlotLines[currentTab] || null;

        if (props.divideBySubId) {

            // Metrics must be compared with a suffix of no1, no2 etc.. SubID is used to differentiate.
            // This way we are sure that data from correct pagination is displayed each time
            const subIdNumber = props.currentSubIdNumber ? props.currentSubIdNumber : props.tabsWithoutNumbers ? currentTab : (currentTab && parseInt(currentTab.match(/\d+/)[0])),
                _dataObj = JSON.parse(JSON.stringify(props.data));

            props.navigationMapper && props.navigationMapper[currentTab] && props.navigationMapper[currentTab].forEach(category => {
                props.chartSeries[currentTab].forEach((serie, index) => {
                    if (category === serie.id) options.series[index].data = _dataObj[`${category}No${subIdNumber}`] || [];
                })
            })

        } else {

            // Metrics already are counted with no1, no2 etc.. No conversion needs to be made to match with axis
            props.navigationMapper && props.navigationMapper[currentTab] && props.navigationMapper[currentTab].forEach(category => {
                props.chartSeries[currentTab].forEach((serie, index) => {
                    if (category === serie.id) options.series[index].data = props.data[category] || [];
                })
            })
        }

        if (monitoringStore().detailedData?.status && props.plotLinesSetter) {
            let plotGuides = props.plotLinesSetter(props.data);
            if (plotGuides?.xAxis) options.xAxis = Object.assign({}, options.xAxis, plotGuides.xAxis);
            if(props.multiplePlotLines && props.multiplePlotLines[currentTab]) options.xAxis.plotLines =  [...options.xAxis.plotLines, ...props.multiplePlotLines[currentTab]];
        }

        setSyncCharts(options);

        // eslint-disable-next-line
    }, [currentTab, props.currentSubIdNumber, props.data])


    return (
        <div className={`syncedChartWithTabNavigation ${props?.className || moment().valueOf()}`}>
            <NavigationTabs 
                classes={{
                    mainClass: 'secondary-navigation-tabs',
                    extraClasses: 'd-flex align-items-center'
                }}
                {...props} 
                currentTab={currentTab} 
                updateCurrentTab={updateCurrentTab}
            />
            <SyncCharts options={syncCharts} />
        </div>
    );
}
export default SyncedChartWithTabNavigation;
