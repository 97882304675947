import meStorage from 'components/monitoringCategories/localStorages/meStorage';
import ThemeColors from "sass/_themeColorsForJs.scss";
import chartOptions from "components/charts/lineChart/react-lineChart";
import chartUtils from 'Utilities/charts';
import { vesselUtils } from 'common/store/storeUtils';
import { downloadChartInCSVform, downloadChartInXLSXform, csvXlsxExportForCurvesAndScatterWithDatetime } from "Utilities/highchartsUtils";

let fuelPowerCurvesData = {}, 
    fuelPowerCurvesDataObj = {}, 
    scatterData = {};

const chartSeries = [  
    {name: 'Actual Data', data: [], tooltipTitle: 'FC', yAxis: 'fuel-axis', type: 'scatter', 
        color: ThemeColors['main_blue'], 
        customTooltipBorderColor: ThemeColors['main_blue'],
        marker: {enabled: true, symbol: 'circle'},
        tooltip: {valueSuffix: ' mt/day', valueDecimals: 0}
    },
    {name: 'Shop Test', data: [], tooltipTitle: 'Shop Test', yAxis: 'fuel-axis', color: ThemeColors['first_reference_line'],
        titleSuffix:'Kn', tootltipXTitle: 'Power: ', tooltip: {valueSuffix: ' mt/day', valueDecimals: 0}},
    {name: 'HFO', data: [], tooltipTitle: 'HFO', yAxis: 'fuel-axis', color: ThemeColors['second_reference_line'],
        titleSuffix:'Kn', tootltipXTitle: 'Power: ', tooltip: {valueSuffix: ' mt/day', valueDecimals: 0}},
    {name: 'LFO', data: [], tooltipTitle: 'LFO', yAxis: 'fuel-axis', color: ThemeColors['third_reference_line'],
        titleSuffix:'Kn', tootltipXTitle: 'Power: ', tooltip: {valueSuffix: ' mt/day', valueDecimals: 0}}
];

const yAxisData = [{id: 'fuel-axis', title: {text: "Fuel Consumption (mt/day)"}, opposite: false}];

const columnHeaderMapper = {
    'Actual Data': 'Actual Data (mt/day)',
    'Shop Test': 'Shop Test (mt/day)',
    'HFO': 'HFO (mt/day)',
    'LFO': 'LFO (mt/day)'
}

// the data that we set here are for the fuel power curves (called in the prerequisite)
const setfuelPowerCurvesData = (data, widget) => {    
    if (widget?.inDashboard) fuelPowerCurvesDataObj[widget.id] = data?.meCurves?.DEFAULT?.curves ? data.meCurves.DEFAULT.curves : {};
    else fuelPowerCurvesData = data?.meCurves?.DEFAULT?.curves ? data.meCurves.DEFAULT.curves : {};
}

const fillSeriesWithData = (series, curvesData, scatterData) => {
        // fill series and update chart's legend for scatter data
        series[0].data = scatterData?.DEFAULT?.SCATTER_PLOT || [];
        series[0].showInLegend = series[0]?.data?.length;

        // fill series and update chart's legend for curves data 
        series[1].data = curvesData?.SHOP_TEST || [];
        series[1].showInLegend = series[1]?.data?.length;

        series[2].data = curvesData?.HFO || [];
        series[2].showInLegend = series[2]?.data?.length;

        series[3].data = curvesData?.LFO || [];
        series[3].showInLegend = series[3]?.data?.length;
}

// the data that we receive here are for the scatter plots
const updateFuelPowerChart = (id, data, updateState, extraChartConfigs, widget) => {

    if(meStorage.fuelPowerScatterPlotsData) scatterData = meStorage.fuelPowerScatterPlotsData;
    else scatterData = data;

    if(meStorage.fuelPowerData) fuelPowerCurvesData = meStorage.fuelPowerData;

    if (widget?.inDashboard) {
        fuelPowerCurvesData = fuelPowerCurvesDataObj[widget?.id];
        scatterData = data;
    }

    // handle empty data case, return empty object so that the noData component appears (show the NoData component when we do not have any scatter data)
    if(!scatterData) return updateState(id, {}, widget?.id);

    const chartData = Object.assign({}, chartOptions(':.2f', '%'));

    // set the series data of the chart
    chartData.yAxis = yAxisData;
    chartData.series = [...chartSeries];
    fillSeriesWithData(chartData.series, fuelPowerCurvesData, scatterData);
    
    // set some chart options that are common in curves-scatter charts
    chartUtils.curvesAndScatterDataChart.setCommonChartOptions(chartData);

    // set extra options that are specific for this chart
    chartData.chart.height = 380;
    chartData.xAxis.title = { text: "Power (kW)", y: 10 };
    chartData.exporting.filename = `${vesselUtils.getVesselName(widget.vesselIds[0])}-MAIN ENGINE FUEL CONSUMPTION VS POWER`;
    chartData.exporting.csv.columnHeaderFormatter = (item) => chartUtils.curvesAndScatterDataChart.setExportingColumns(item, columnHeaderMapper, 'Power (kW)');

    chartData.exporting.buttons.contextButton= {
        menuItems: [
            "download",
            "customSeparator",
            {
                text: "XLSX",
                onclick: function () {
                    const dto = csvXlsxExportForCurvesAndScatterWithDatetime(this, 'kW', 'mt/day')
                    downloadChartInXLSXform({xlsxRows: dto.rows, sheetsName: dto.sheetsName, name: dto.name});
                }
            },
            {
                text: "CSV",
                onclick: function () {
                    const dto = csvXlsxExportForCurvesAndScatterWithDatetime(this, 'kW', 'mt/day')
                    const csvContent = dto.rows.map(row => row.join(',')).join('\n');
                    downloadChartInCSVform({csvContent, name: dto.sheetsName});
                }
            },
        ]
    }
    // a common formatter for creating the tooltip's UI for both scatter data and curves data
    const tooltipExtraOptions = {
        titleX: 'Power: ',
        valueXSuffix: 'kW',
    };
    chartData.tooltip.formatter = chartData.tooltip.formatter = function() {return chartUtils.curvesAndScatterDataChart.tooltipFormatter.call(this, tooltipExtraOptions)};

    if (!widget?.inDashboard) {
        meStorage.setFuelPowerData(fuelPowerCurvesData);
        meStorage.setFuelPowerScatterPlotsData(scatterData);
    }

    fuelPowerCurvesData = {};
    scatterData = {};

    return updateState(id, chartData, widget?.id);
}

export { updateFuelPowerChart, setfuelPowerCurvesData };
